import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import TimeAgo from 'react-timeago';
import spotifyLogo from './Spotify_Primary_Logo_RGB_Green.png';
import appleMusicLogo from './256px-Apple_Music_icon.svg.png'
import missingImage from './missing_image.jpg'
// import { getSpotifyTrackInfo } from './sharedFunctions';

const handleRowClick = (
  params, // GridRowParams
  event, // MuiEvent<React.MouseEvent<HTMLElement>>
  details, // GridCallbackDetails
) => {

  // grab Spotify token, call Spotify with trackId
  // pass returned item to player via Cookie
  // getSpotifyTrackInfo(params.row.trackId)

  var win = window.open('https://open.spotify.com/track/' + params.row.trackId, '_blank');
  win.focus();
};

const columns = [
  { field: 'id', headerName: 'Played At', 
    width: 100,
    renderCell: (params) => (
      <TimeAgo date={Number(params.value)} />
    ),
  },
  {
    field: 'albumImage',
    headerName: '',
    width: 75,
    renderCell: (params) => {
      if (params.value === undefined) {
       return(<img alt='Missing' src={missingImage} width='50px' />)
      } else if (params.value === '') {
        return(<img alt='Missing' src={missingImage} width='50px' />)
      } else {
        return(<img alt='Artwork' src={params.value} width='50px' />)
      }
    },
  },
  {
    field: 'trackId',
    headerName: 'Track Id',
    width: 100
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 100
  },
  {
    field: 'artist',
    headerName: 'Artist',
    width: 100
  },
  {
    field: 'musicService',
    headerName: 'Service',
    width: 75,
    renderCell: (params) => {
      if (params.value === 'AppleMusic') {
       return(<img alt='Apple Music' src={appleMusicLogo} width='50px' />)
      } else if (params.value === 'Spotify') {
        return(<img alt='Spotify' src={spotifyLogo} width='50px' />)
      }
    },
  },
];

function normalizedRecentlyPlayedData(jsonData) {
  return (jsonData.map(item => {
    var url = item.albumImageUrl || '';
    url = url.replace('{w}', '50');
    url = url.replace('{h}', '50');
    return ({
      id: item.playedAt,
      trackId: item.trackId,
      title: item.trackName,
      artist: item.artist,
      musicService: item.musicService,
      albumImage: url
    });
  }))
}

function GetNormalizedRecentlyPlayed(perspective) {
  const [loading, setLoading] = useState(true);
  const [localFriend, setLocalFriend] = useState(Cookies.get('currentFriendSub'));
  const [currentUserRecentlyPlayedData, setRecentlyPlayedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/get-normalized-recently-played' +
          ((perspective === 'CurrentUser') ? '' : '?sub=' + localFriend ), {
          headers: {
            "Authorization": "Bearer " + Cookies.get('apiToken')
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData)
        setRecentlyPlayedData(normalizedRecentlyPlayedData(jsonData))
      } catch (error) {
        setRecentlyPlayedData([{
          id: '1',
          title: 'Error',
          artist: ''
        }]);
        console.error('Error fetching recently played:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000); // Fetch every 60 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount

  }, [perspective, localFriend]);

  // Update friend with every render when its value has changed.
  const friendCookie = Cookies.get("currentFriendSub");
  if (localFriend !== friendCookie ) {
    setLocalFriend(friendCookie);
  }

  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        Your {((perspective === 'Friend') ? 'friend, ' + Cookies.get("currentFriendName") + '\'s': '')} track history
      </Typography>
      {loading && <div><CircularProgress /></div>}
      {!loading &&
      <div>
        <Box pb={10} sx={{ height: '100%', width: '100%' }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            onRowClick={handleRowClick}
            rows={currentUserRecentlyPlayedData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
              columns: {
                columnVisibilityModel: {
                  trackId: false,
                },
              },
            }}
            pageSizeOptions={[50]}
          />
        </Box>
      </div>}
    </div>
  )
}

export default GetNormalizedRecentlyPlayed;